import React, {useState} from "react";
import * as S from "./styles";
import {TogglePanel} from "../TogglePanel/TogglePanel";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {configActions} from "../../redux/slices/config-slice";
import {CampaignType, MarketoCampaign, ProductConfig} from "../../models/marketo-config";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {EditCampaignModal} from "../EditCampaignModal/EditCampaignModal";
import {campaignTypeToDropdownOption, getCampaignLink} from "../../utils/utils";
import {Icon} from "../Icon/Icon";
import {AiOutlinePlusCircle} from "react-icons/ai";
import {NewCampaignConfigModal} from "../NewCampaignConfigModal/NewCampaignConfigModal";
import confirm from "@jetbrains/ring-ui/dist/confirm-service/confirm-service";
import Select from "@jetbrains/ring-ui/dist/select/select";

export const CampaignsConfigSection: React.FC = () => {

    const dispatch = useAppDispatch();
    const marketoConfig = useAppSelector(state => state.config.marketoConfig);
    const knownProducts = useAppSelector(state => state.content.products);
    const sortedProducts: ProductConfig[] = ([...marketoConfig?.products ?? []]).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
    const [campaignToEdit, setCampaignToEdit] = useState<MarketoCampaign | null>(null);
    const [productForNewCampaign, setProductForNewCampaign] = useState<string | null>(null);

    function deleteCampaign(campaignId: number) {
        confirm({
            text: "Delete campaign?",
            description: `Are you sure you want to delete the configuration for Marketo campaign #${campaignId}?`,
        }).then(() => dispatch(configActions.deleteCampaign({campaignId})));
    }

    return <div>
        <S.Title>Configured Marketo campaigns:</S.Title>
        <S.InfoBlock>
            <p>Supported campaign types:</p>
            {[CampaignType.SINGLE_POST_SENDOUTS, CampaignType.DIGEST_SENDOUTS, CampaignType.UNIVERSAL].map(type => <p key={type}>
                {getTag(type)} – {campaignTypeToDropdownOption(type).label}
            </p>)}
        </S.InfoBlock>
        {sortedProducts.map(product => <TogglePanel key={product.name} title={product.name}>
            <S.List>
                {product.campaigns.map(campaign => <S.ListItem key={campaign.id}>
                    <S.CampaignTitle>{campaign.name}{getTag(campaign.type)}{campaign.internal && getTag("internal")}</S.CampaignTitle>
                    <S.CampaignDescription style={{flexGrow: 1}}>{campaign.description.replace(/^#[^ ]+ \(/, "").replace(/\)$/, "")}</S.CampaignDescription>
                    <S.CampaignNumberLabel>Campaign ID: <a href={getCampaignLink(campaign.id)} target="_blank">{campaign.id}</a></S.CampaignNumberLabel>
                    <S.ButtonsGroup>
                        <Button onClick={() => setCampaignToEdit(campaign)} primary>Edit</Button>
                        <Button onClick={() => deleteCampaign(campaign.id)} danger>Delete</Button>
                    </S.ButtonsGroup>
                </S.ListItem>)}
                <S.ListItem style={{backgroundColor: "#EEE"}}>
                    <Icon Type={AiOutlinePlusCircle} style={{fontSize: "48px", color: "#CCC"}}/>
                    <p>You can add new configuration for this product</p>
                    <Button primary onClick={() => setProductForNewCampaign(product.name)}>Add new</Button>
                </S.ListItem>
            </S.List>
        </TogglePanel>)}

        {knownProducts && <div>
            <p>Missing product? Add the first campaign for it:</p>
            <Select
                selected={productForNewCampaign ? {label: knownProducts.find(p => p.label === productForNewCampaign)!.label, key: productForNewCampaign, value: productForNewCampaign} : null}
                data={[
                    ...knownProducts.filter(p => !marketoConfig?.products.some(_p => _p.name === p.label)).map(product => ({label: product.label, key: product.value, value: product.value}))
                ]}
                onChange={(product: any) => setProductForNewCampaign(product.label)}
            />
            <p style={{margin: "4px 0 0 0", fontSize: "0.7em", color: "#777"}}>⚠️ Adding the first configuration for a product will require JetBlog restart</p>
        </div>}

        {campaignToEdit && <EditCampaignModal show={true} onClose={() => setCampaignToEdit(null)} campaign={campaignToEdit}/>}
        {productForNewCampaign && <NewCampaignConfigModal open={true} product={productForNewCampaign} onClose={() => setProductForNewCampaign(null)}/>}
    </div>;

};

function getTag(name: string | CampaignType) {
    switch (name) {
        case "internal":
            return <S.Tag color="#F48C8C">internal</S.Tag>;
        case CampaignType.SINGLE_POST_SENDOUTS:
            return <S.Tag color="#bdffbf">SPS</S.Tag>;
        case CampaignType.DIGEST_SENDOUTS:
            return <S.Tag color="#a2befa">DS</S.Tag>;
        case CampaignType.UNIVERSAL:
            return <S.Tag color="#bdfff0">U</S.Tag>;
        default:
            throw Error("Unknown tag: " + name);
    }
}
