import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import {CampaignType, MarketoCampaign} from "../../models/marketo-config";
import Input, {Size} from "@jetbrains/ring-ui/dist/input/input";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {configActions} from "../../redux/slices/config-slice";
import {ToggleableProcessor} from "../ToggleableProcessor/ToggleableProcessor";
import Checkbox from "@jetbrains/ring-ui/dist/checkbox/checkbox";
import {campaignTypeToDropdownOption, validateField} from "../../utils/utils";
import Select from "@jetbrains/ring-ui/dist/select/select";

interface Props {
    show: boolean;
    onClose(): void;
    campaign: MarketoCampaign;
}

const idRegex = /^[1-9]\d*$/;

export const EditCampaignModal: React.FC<Props> = (props) => {

    const dispatch = useAppDispatch();
    const {show, onClose, campaign} = props;
    const processors = useAppSelector(state => state.config.knownProcessors);
    const showDebugFields = useAppSelector(state => state.users.currentUserInfo?.isDeveloper ?? false);

    const [type, setType] = useState<CampaignType>(campaign.type ?? CampaignType.SINGLE_POST_SENDOUTS);
    const [name, setName] = useState(campaign.name);
    const [description, setDescription] = useState(campaign.description);
    const [emailId, setEmailId] = useState(campaign.email.toString());
    const [campaignId, setCampaignId] = useState(campaign.id.toString());
    const [programId, setProgramId] = useState(campaign.program.toString());
    const [selectedPreprocessors, setSelectedPreprocessors] = useState(campaign.preprocessors);
    const [selectedPostprocessors, setSelectedPostprocessors] = useState(campaign.postprocessors);
    const [internal, setInternal] = useState(campaign.internal);
    const [additionalCss, setAdditionalCss] = useState(campaign.additionalCss ?? "");
    const [suppressNotification, setSuppressNotification] = useState(false);

    const formIsUpdated = description !== campaign.description || emailId !== campaign.email.toString() ||
        campaignId !== campaign.id.toString() || programId !== campaign.program.toString() || processorsAreUpdated(selectedPreprocessors, campaign.preprocessors) ||
        processorsAreUpdated(selectedPostprocessors, campaign.postprocessors) || internal !== campaign.internal || name !== campaign.name ||
        additionalCss !== (campaign.additionalCss ?? "") || type !== (campaign.type ?? CampaignType.SINGLE_POST_SENDOUTS);
    const formIsValid = formIsUpdated && idRegex.test(emailId) && idRegex.test(campaignId) && idRegex.test(programId)
        && description.trim() !== "" && name.trim() !== "";

    useEffect(() => {
        if (processors === null) {
            dispatch(configActions.loadKnownProcessors());
        }
    }, []);

    const save = () => {
        dispatch(configActions.updateCampaign({
            campaignId: campaign.id,
            newCampaignId: campaignId === campaign.id.toString() ? undefined : Number(campaignId),
            description: description,
            emailId: Number(emailId),
            programId: Number(programId),
            preprocessors: selectedPreprocessors,
            postprocessors: selectedPostprocessors,
            internal: internal,
            name: name,
            additionalCss: additionalCss.trim() === "" ? null : additionalCss,
            suppressNotification: suppressNotification,
            type: type,
        }));
        onClose();
    };

    const togglePreprocessor = (preprocessor: string) => {
        if (selectedPreprocessors.includes(preprocessor)) {
            setSelectedPreprocessors(selectedPreprocessors.filter(p => p !== preprocessor));
        } else {
            setSelectedPreprocessors([...selectedPreprocessors, preprocessor]);
        }
    };

    const togglePostprocessor = (postprocessor: string) => {
        if (selectedPostprocessors.includes(postprocessor)) {
            setSelectedPostprocessors(selectedPostprocessors.filter(p => p !== postprocessor));
        } else {
            setSelectedPostprocessors([...selectedPostprocessors, postprocessor]);
        }
    };

    return <Modal show={show} title="Edit campaign" onClose={onClose} actions={[{label: "Save", fn: save, disabled: !formIsValid, primary: true}]}>
        <span style={{fontSize: "0.8em", color: "#777", display: "block", margin: "0 0 4px"}}>Campaign type</span>
        <Select
            selected={campaignTypeToDropdownOption(type)}
            data={[CampaignType.SINGLE_POST_SENDOUTS, CampaignType.DIGEST_SENDOUTS, CampaignType.UNIVERSAL].map(campaignTypeToDropdownOption)}
            size={Size.FULL}
            onChange={(value: any) => setType(value.value)}
        />
        <Input
            value={name}
            onChange={e => setName(e.target.value)}
            label="Name"
            size={Size.FULL}
            error={validateField(name, {nonEmpty: true})}
        />
        <Input
            value={description}
            onChange={e => setDescription(e.target.value)}
            label="Description"
            size={Size.FULL}
        />
        <Input
            value={campaignId}
            onChange={e => setCampaignId(e.target.value)}
            label="Campaign ID"
            size={Size.FULL}
            error={validateField(campaignId, {nonEmpty: true, positiveNumber: true})}
        />
        <Input
            value={programId}
            onChange={e => setProgramId(e.target.value)}
            label="Program ID"
            size={Size.FULL}
            error={validateField(programId, {nonEmpty: true, positiveNumber: true})}
        />
        <Input
            value={emailId}
            onChange={e => setEmailId(e.target.value)}
            label="Email ID"
            size={Size.FULL}
            error={validateField(emailId, {nonEmpty: true, positiveNumber: true})}
        />
        {showDebugFields && <Input
            value={additionalCss}
            onChange={e => setAdditionalCss(e.target.value)}
            label="Additional CSS path (optional)"
            size={Size.FULL}
        />}
        {processors && type !== CampaignType.DIGEST_SENDOUTS && <>
            <div>
                <S.Label>Preprocessors:{type === CampaignType.UNIVERSAL ? " (for single-post sendouts only)" : ""}</S.Label>
                {processors.preprocessors.map(p => {
                    const active = selectedPreprocessors.includes(p);
                    return <ToggleableProcessor key={p} name={p} active={active}
                                                onToggle={() => togglePreprocessor(p)}/>;
                })}
            </div>
            <div>
                <S.Label>Postprocessors:{type === CampaignType.UNIVERSAL ? " (for single-post sendouts only)" : ""}</S.Label>
                {processors.postprocessors.map(p => {
                    const active = selectedPostprocessors.includes(p);
                    return <ToggleableProcessor key={p} name={p} active={active}
                                                onToggle={() => togglePostprocessor(p)}/>;
                })}
            </div>
        </>}
        <S.CheckboxContainer>
            <Checkbox
                checked={internal}
                onChange={() => setInternal(!internal)}
                label="Available only for MAU users"
            />
            <Checkbox
                checked={suppressNotification}
                onChange={() => setSuppressNotification(!suppressNotification)}
                label="Suppress a Slack-notification about this change"
            />
        </S.CheckboxContainer>
    </Modal>;

};

function processorsAreUpdated(selected: string[], current: string[]): boolean {
    return selected.length !== current.length || selected.some(p => !current.includes(p));
}
