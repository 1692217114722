import React, {ReactNode} from "react";
import * as S from "./styles";
import classNames from "classnames";
import {Icon} from "../Icon/Icon";
import {CgSpinnerTwoAlt} from "react-icons/cg";

type Display = "block" | "inline-block";
type Variant = "default" | "magic-gradient";

interface Props {
    children: ReactNode;
    onClick(): void;

    display?: Display;
    loading?: boolean;
    variant?: Variant;
    disabled?: boolean;
}

export const JetblogButton: React.FC<Props> = (props) => {

    const {
        children,
        onClick,
        display,
        loading,
        variant,
        disabled,
    } = props;

    return <S.StyledButton
        onClick={() => onClick()}
        style={{
            display: display ?? "inline-block",
        }}
        className={classNames({magic: variant === "magic-gradient"})}
        disabled={disabled || loading}
    >
        {loading
            ? <div className="spinning"><Icon Type={CgSpinnerTwoAlt}/></div>
            : children
        }
    </S.StyledButton>

};
