import React, {useEffect, useState} from "react";
import * as S from "./styles";
import * as SS from "../common-styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {contentActions} from "../../redux/slices/content-slice";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {ProductDescriptor} from "../../models/product-descriptor";
import {PostsList} from "../PostsList/PostsList";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {ContentDescriptor} from "../../models/content-descriptor";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {IoMdRefresh} from "react-icons/io";
import ErrorMessage from "@jetbrains/ring-ui/dist/error-message/error-message";
import {AiOutlineFrown} from "react-icons/ai";
import {CampaignSelectorDialog} from "../CampaignSelectorDialog/CampaignSelectorDialog";
import {RequestAccessModal} from "../RequestAccessModal/RequestAccessModal";
import confirm from "@jetbrains/ring-ui/dist/confirm-service/confirm-service";
import Pager from "@jetbrains/ring-ui/dist/pager/pager";
import {StatusPanel} from "../StatusPanel/StatusPanel";
import {PostsListV2} from "../PostsListV2/PostsListV2";
import {DigestPicker} from "../DigestPicker/DigestPicker";
import {RiApps2AddLine} from "react-icons/ri";
import {CampaignType} from "../../models/marketo-config";

export const FeedPage: React.FC = () => {

    const {products, selectedProduct, postsPage, refreshing} = useAppSelector(state => state.content);
    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const dispatch = useAppDispatch();
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [postForSendout, setPostForSendout] = useState<ContentDescriptor | null>(null);
    const [creating, setCreatingState] = useState(false);
    const [showDigestPicker, setShowDigestPicker] = useState(false);

    // preselect something initially:
    useEffect(() => {
        const availableProducts = products?.filter(p => p.permitted) ?? [];
        if (availableProducts.length > 0 && selectedProduct === null) {
            dispatch(contentActions.selectProduct( availableProducts.length > 1 ? "all" : availableProducts[0]));
        }
    }, [products]);

    const onProductSelected = (product: ProductDescriptor) => {
        dispatch(contentActions.selectProduct(product));
    };

    const onCampaignSelected = (campaignId: number) => {
        setCreatingState(true);
        dispatch(sendoutsActions.createSendout({contentId: postForSendout!.id, title: postForSendout!.title, campaignId}));
    };

    const forceUpdate = () => {
        dispatch(contentActions.forceUpdateForSelectedProduct());
    };

    const deletePost = (content: ContentDescriptor) => {
        confirm({
            text: "Delete the post?",
            description: "You can delete the post if it was added to this feed because of an error on the blog side. " +
                "If it still exists in the blog – it may be added back during the next update cycle. Existing " +
                "sendouts will not be affected.",
        }).then(() => dispatch(contentActions.deletePost(content.id)))
            .catch(() => {});
    };

    const setPage = (page: number) => {
        if (page >= 0 && page < postsPage!.totalPages) {
            dispatch(contentActions.updatePostsForSelectedProduct({page}));
        }
    };

    if (products === null) {
        return <Loader message="Loading available products"/>;
    } else {
        return <SS.PageLayout>
            {newUiEnabled && <SS.SidePanel>
                <p>Select a blog to create a sendout:</p>
                <div>
                    <Select
                        selected={selectedProduct ? {
                            label: selectedProduct === "all" ? "All available" : selectedProduct.label,
                            key: selectedProduct === "all" ? "all" : selectedProduct.value,
                            value: selectedProduct as any
                        } : null}
                        data={[
                            ...(products.filter(p => p.permitted).length > 1 ? [{
                                label: "All available",
                                key: "all",
                                value: "all"
                            }] : []),
                            ...products.filter(p => p.permitted).map(product => ({
                                label: product.label,
                                key: product.value,
                                value: product
                            }))
                        ]}
                        onChange={(product: any) => onProductSelected(product.value)}
                        disabled={refreshing}
                    />
                </div>
                {selectedProduct && selectedProduct !== "all" && <S.ActionsContainer>
                    <Button primary onClick={forceUpdate} loader={refreshing}>
                        <IoMdRefresh style={{transform: "translateY(2px)"}}/> Refresh
                    </Button>
                    <Button primary onClick={() => setShowDigestPicker(true)} disabled={refreshing}>
                        <RiApps2AddLine style={{transform: "translateY(2px)"}}/> Create digest
                    </Button>
                </S.ActionsContainer>}
                <p style={{fontSize: "0.8em", color: "#777", margin: 0}}>
                    Missing a product you need? <Button text onClick={() => setShowAccessModal(true)}
                                                        style={{padding: 0}}>Request</Button> permissions for it
                    now.
                </p>
            </SS.SidePanel>}

            <div style={{flexGrow: 1, padding: newUiEnabled ? "0 0 8px" : "8px", overflow: "auto"}}>
                {products.filter(p => p.permitted).length === 0 && <ErrorMessage
                    message="No available products"
                    description="We can not find any products configured for your account. Please ask MAU Team to grant access."
                    icon={AiOutlineFrown}
                    className="wide-error-message"
                >
                    <p>
                        You can <Button text onClick={() => setShowAccessModal(true)} style={{padding: "0 6px"}}>send a
                        request right here</Button> or
                        just ask for assistance in our <Button text
                                                               onClick={() => window.open("https://jetbrains.slack.com/archives/C060BGP1C", "_blank")}
                                                               style={{padding: "0 6px"}}>Slack channel</Button>
                    </p>
                </ErrorMessage> || <>
                    <StatusPanel newUi={newUiEnabled}/>

                    {!newUiEnabled && <>
                        <p>Select a blog to create a sendout:</p>
                        <div style={{
                            display: "flex",
                            flexFlow: "row nowrap",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Select
                                selected={selectedProduct ? {
                                    label: selectedProduct === "all" ? "All available" : selectedProduct.label,
                                    key: selectedProduct === "all" ? "all" : selectedProduct.value,
                                    value: selectedProduct as any
                                } : null}
                                data={[
                                    ...(products.filter(p => p.permitted).length > 1 ? [{
                                        label: "All available",
                                        key: "all",
                                        value: "all"
                                    }] : []),
                                    ...products.filter(p => p.permitted).map(product => ({
                                        label: product.label,
                                        key: product.value,
                                        value: product
                                    }))
                                ]}
                                onChange={(product: any) => onProductSelected(product.value)}
                                disabled={refreshing}
                            />
                            {selectedProduct && selectedProduct !== "all" &&
                                <Button primary onClick={forceUpdate} loader={refreshing}>
                                    <IoMdRefresh style={{transform: "translateY(2px)"}}/> Refresh
                                </Button>}
                        </div>
                        <p style={{fontSize: "0.8em", color: "#777", margin: 0}}>
                            Missing a product you need? <Button text onClick={() => setShowAccessModal(true)}
                                                                style={{padding: 0}}>Request</Button> permissions for it
                            now.
                        </p>
                    </>}

                    {selectedProduct && (postsPage && <>
                        {newUiEnabled
                            ? <PostsListV2
                                posts={postsPage.content}
                                onCreate={setPostForSendout}
                                onDelete={deletePost}
                                showProductLabel={selectedProduct === "all"}
                            />
                            : <PostsList
                                posts={postsPage.content}
                                onCreate={setPostForSendout}
                                onDelete={deletePost}
                                showProductLabel={selectedProduct === "all"}
                            />
                        }
                        <Pager
                            total={postsPage.totalElements}
                            currentPage={postsPage.number + 1}
                            onPageChange={page => setPage(page - 1)}
                            disablePageSizeSelector
                            pageSize={postsPage.size}
                        />
                        {postForSendout && <CampaignSelectorDialog
                            show={true}
                            productTypes={postForSendout.productNames}
                            onClose={() => setPostForSendout(null)}
                            onCampaignSelected={onCampaignSelected}
                            loading={creating}
                            allowedCampaignTypes={[CampaignType.SINGLE_POST_SENDOUTS, CampaignType.UNIVERSAL]}
                        />}
                    </> || <Loader message="Loading posts"/>)}
                </>}
                <RequestAccessModal show={showAccessModal} onClose={() => setShowAccessModal(false)} products={products}/>
                {selectedProduct !== "all" && selectedProduct !== null && <DigestPicker
                    open={showDigestPicker}
                    onClose={() => setShowDigestPicker(false)}
                    product={selectedProduct}
                />}
            </div>
        </SS.PageLayout>;
    }

};
