import styled from "styled-components";

export const FieldLabel = styled.label`
  display: block;
  font-size: 0.9em;
  margin: 10px 0 4px;
`;

export const ImagePreviewLarge = styled.img`
  max-width: 100%;
  max-height: 140px;
  display: block;
  margin: 8px auto;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export const ImagePreviewSmall = styled.img`
  height: 80px;
  display: block;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  justify-content: center;
`;

export const ButtonPreview = styled.button`
  border: 1px solid #BABABB;
  color: #19191C;
  text-decoration: none;
  padding: 14px 45px;
  border-radius: 25px;
  font-weight: 500;
  display: inline-block;
  background-color: white;
  flex-shrink: 0;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: darkred;
`;
