export interface MarketoConfig {
    products: ProductConfig[];
}

export interface ProductConfig {
    name: string;
    campaigns: MarketoCampaign[];
}

export interface MarketoCampaign {
    id: number;
    name: string;
    description: string;
    email: number;
    program: number;
    preprocessors: string[];
    postprocessors: string[];
    internal: boolean;
    additionalCss?: string | null;
    type: CampaignType;
}

export enum CampaignType {
    SINGLE_POST_SENDOUTS = "SINGLE_POST_SENDOUTS",
    DIGEST_SENDOUTS = "DIGEST_SENDOUTS",
    UNIVERSAL = "UNIVERSAL",
}
