import {combineEpics, Epic, ofType} from "redux-observable";
import {
    CreateDigestActionPayload, DeleteDigestItemActionPayload,
    digestsActions,
    EditDigestActionPayload,
    EditDigestItemActionPayload, SendDigestSampleActionPayload
} from "../slices/digests-slice";
import {catchError, ignoreElements, map, of, switchMap, tap} from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";
import {DigestsApi} from "../../api/digests-api";
import {miscActions} from "../slices/misc-slice";
import {DigestSendout} from "../../models/digest-sendout";
import {history} from "../../components/CustomRouter/CustomRouter";
import {AppPage} from "../../models/pages";
import {CONFIG} from "../../config";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";

const createDigest$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.createDigest),
    switchMap((action: PayloadAction<CreateDigestActionPayload>) => DigestsApi.createDigest(action.payload.campaignId, action.payload.contentIds).pipe(
        map(digest => digestsActions.onDigestCreated(digest)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onDigestCreated$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.onDigestCreated),
    tap((action: PayloadAction<DigestSendout>) => {
        history.push(`${AppPage.DIGEST_EDITOR}/${(action.payload.id)}`);
        alertService.successMessage("New digest created", CONFIG.defaultAlertsTimeoutMs);
    }),
    ignoreElements(),
);

const loadDigest$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.loadDigestToEdit),
    switchMap((action: PayloadAction<string>) => DigestsApi.getDigest(action.payload).pipe(
        map(digest => digestsActions.onDigestToEditUpdated(digest)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const editDigest$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.editDigest),
    switchMap((action: PayloadAction<EditDigestActionPayload>) => DigestsApi.editDigest(action.payload).pipe(
        map(digest => digestsActions.onDigestToEditUpdated(digest)),
        tap(() => {
            alertService.successMessage("Changes have been saved", CONFIG.defaultAlertsTimeoutMs);
        }),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const editDigestItem$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.editDigestItem),
    switchMap((action: PayloadAction<EditDigestItemActionPayload>) => DigestsApi.editDigestItem(action.payload).pipe(
        map(digest => digestsActions.onDigestToEditUpdated(digest)),
        tap(() => {
            alertService.successMessage("Changes have been saved", CONFIG.defaultAlertsTimeoutMs);
        }),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const deleteDigestItem$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.deleteDigestItem),
    switchMap((action: PayloadAction<DeleteDigestItemActionPayload>) => DigestsApi.deleteDigestItem(action.payload).pipe(
        map(digest => digestsActions.onDigestToEditUpdated(digest)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const sendDigestSample$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.sendDigestSample),
    switchMap((action: PayloadAction<SendDigestSampleActionPayload>) => DigestsApi.sendSample(action.payload.digestId, action.payload.customEmail).pipe(
        map(digestsActions.onSampleSent),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSampleSent$: Epic = (action$) => action$.pipe(
    ofType(digestsActions.onSampleSent),
    tap(() => {
        alertService.successMessage("Digest sample email has been sent normally", CONFIG.defaultAlertsTimeoutMs);
    }),
    ignoreElements(),
);

export const digestsEpics = combineEpics(
    createDigest$,
    onDigestCreated$,
    loadDigest$,
    editDigest$,
    editDigestItem$,
    deleteDigestItem$,
    sendDigestSample$,
    onSampleSent$,
);
