import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DigestSendout} from "../../models/digest-sendout";
import {Page} from "../../models/page";

interface DigestsState {
    digestToEdit: DigestSendout | null;
}

const initialState: DigestsState = {
    digestToEdit: null,
};

const digestsSlice = createSlice({
    name: "digests",
    initialState: initialState,
    reducers: {
        createDigest: (state, action: PayloadAction<CreateDigestActionPayload>) => {},
        onDigestCreated: (state, action: PayloadAction<DigestSendout>) => {},
        loadDigestToEdit: (state, action: PayloadAction<string>) => {},
        onDigestToEditUpdated: (state, action: PayloadAction<DigestSendout>) => {
            state.digestToEdit = action.payload;
        },
        editDigest: (state, action: PayloadAction<EditDigestActionPayload>) => {},
        editDigestItem: (state, action: PayloadAction<EditDigestItemActionPayload>) => {},
        deleteDigestItem: (state, action: PayloadAction<DeleteDigestItemActionPayload>) => {},
        sendDigestSample: (state, action: PayloadAction<SendDigestSampleActionPayload>) => {},
        onSampleSent: (state, action: PayloadAction<DigestSendout>) => {},
    },
});

export const digestsActions = digestsSlice.actions;
export const digestsReducer = digestsSlice.reducer;

export type CreateDigestActionPayload = {campaignId: number, contentIds: string[]};
export type EditDigestActionPayload = {digestId: string, title?: string | null, authorName?: string | null, campaignId?: number | null, description?: string | null};
export type EditDigestItemActionPayload = {digestId: string, itemId: string, title?: string | null, selectedContent?: string | null, index?: number | null, selectedImage?: string, selectedButton?: {url: string, label: string}};
export type DeleteDigestItemActionPayload = {digestId: string, itemId: string};
export type SendDigestSampleActionPayload = {digestId: string, customEmail?: string | null};
