import axios from "axios-observable";
import {map, Observable} from "rxjs";
import {DigestSendout} from "../models/digest-sendout";
import {CONFIG} from "../config";
import {
    DeleteDigestItemActionPayload,
    EditDigestActionPayload,
    EditDigestItemActionPayload
} from "../redux/slices/digests-slice";

export namespace DigestsApi {

    export function createDigest(campaignId: number, contentIds: string[]): Observable<DigestSendout> {
        return axios.post(`${CONFIG.apiHost}api/digests/`, {campaignId, contentIds}, {withCredentials: true}).pipe(
            map(response => DigestSendout.parse(response.data)),
        );
    }

    export function getDigest(id: string): Observable<DigestSendout> {
        return axios.get(`${CONFIG.apiHost}api/digests/${id}`, {withCredentials: true}).pipe(
            map(response => DigestSendout.parse(response.data)),
        );
    }

    export function editDigest(payload: EditDigestActionPayload): Observable<DigestSendout> {
        const body = {
            title: payload.title,
            authorName: payload.authorName,
            campaignId: payload.campaignId,
            description: payload.description,
        };
        return axios.patch(`${CONFIG.apiHost}api/digests/${payload.digestId}`, body, {withCredentials: true}).pipe(
            map(response => DigestSendout.parse(response.data)),
        );
    }

    export function editDigestItem(payload: EditDigestItemActionPayload): Observable<DigestSendout> {
        const body = {
            title: payload.title,
            selectedContent: payload.selectedContent,
            index: payload.index,
            selectedImage: payload.selectedImage,
            selectedButton: payload.selectedButton,
        };
        return axios.patch(`${CONFIG.apiHost}api/digests/${payload.digestId}/item`, body, {params: {id: payload.itemId}, withCredentials: true}).pipe(
            map(response => DigestSendout.parse(response.data)),
        );
    }

    export function deleteDigestItem(payload: DeleteDigestItemActionPayload): Observable<DigestSendout> {
        return axios.delete(`${CONFIG.apiHost}api/digests/${payload.digestId}/item`, {params: {id: payload.itemId}, withCredentials: true}).pipe(
            map(response => DigestSendout.parse(response.data)),
        );
    }

    export function sendSample(digestId: string, customEmail: string | null | undefined): Observable<any> {
        return axios.post(`${CONFIG.apiHost}api/digests/${digestId}/sample`, {customEmail}, {withCredentials: true});
    }

    export function generateItemContent(digestId: string, itemId: string): Observable<string> {
        return axios.post(`${CONFIG.apiHost}api/digests/${digestId}/item/regenerate`, null, {withCredentials: true, params: {id: itemId}}).pipe(
            map(response => response.data.content as string),
        );
    }

}
