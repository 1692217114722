import styled from "styled-components";

export const EditorPageLayout = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const EditorLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
`;

export const SidePanel = styled.div`
  width: 600px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 0 12px;
  color: whitesmoke;
  background-color: #222;
  input {
    color: whitesmoke !important;
  }
  div[data-test="ring-input"] > label {
    color: whitesmoke;
    font-size: 0.9em;
    margin-top: 12px;
    &::after {
      content: ":";
    }
  }
`;

export const PreviewContainer = styled.div`
  flex-grow: 1;
  border-left: 1px solid #CCC;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
`;

export const FieldsSectionTitle = styled.h3`
  text-align: center;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 1em;
  &:not(:first-child) {
    border-top: 1px solid #777;
    padding-top: 8px;
  }
`;

export const FieldsList = styled.div`
  
`;

export const FieldLabel = styled.label`
  margin: 16px 0 8px;
  display: block;
  font-size: 0.9em;
`;

export const ItemsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;

export const ItemLabel = styled.label`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Iframe = styled.iframe`
  display: block;
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  margin: auto;
  padding: 20px;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  margin-top: 16px;
  justify-content: center;
  & > * {
    margin: 0;
  }
`;
