import React, {useEffect, useState} from "react";
import Dialog from "@jetbrains/ring-ui/dist/dialog/dialog";
import * as S from "./styles";
import * as SS from "../common-styles";
import {CampaignType, MarketoCampaign} from "../../models/marketo-config";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {useAppSelector} from "../../redux/store";
import {campaignTypeToDropdownOption} from "../../utils/utils";

interface Props {
    productTypes: string[];
    onCampaignSelected(campaignId: number): void;
    currentCampaignIsInvalid?: boolean;
    allowedCampaignTypes: CampaignType[];
}

export const CampaignSelector: React.FC<Props> = (props) => {

    const {productTypes, onCampaignSelected, currentCampaignIsInvalid, allowedCampaignTypes} = props;

    const marketoConfig = useAppSelector(state => state.config.marketoConfig);
    const mauUser = useAppSelector(state => state.users.currentUserInfo?.isAdmin ?? false);
    const products = useAppSelector(state => state.content.products);

    const [selectedProductType, setSelectedProductType] = useState<string | null>(productTypes.length === 1 ? productTypes[0] : null);
    const [selectedCampaign, setSelectedCampaign] = useState<MarketoCampaign | null>(null);

    const selectedProductLabel = products?.find(p => p.value === selectedProductType)?.label ?? null;

    useEffect(() => {
        setSelectedCampaign(null);
    }, []); //TODO

    useEffect(() => {
        if (selectedCampaign) {
            onCampaignSelected(selectedCampaign.id);
        }
    }, [selectedCampaign]);

    return <>
        <S.Title>Select campaign for a <b>{selectedProductLabel}</b> sendout</S.Title>
        {(marketoConfig === null || products === null)
            ? <Loader message="Loading configuration"/>
            : <>
                <S.CampaignInputs>
                    {currentCampaignIsInvalid && <p>The currently selected campaign is invalid. Please choose another one.</p>}
                    {(selectedProductType === null || selectedProductLabel === null)
                        ? <>
                            <p>This post is associated with multiple products. Please choose one of them to continue.</p>
                            <Select
                                label="Product"
                                data={productTypes.map(t => products!.find(p => p.value === t)!).map(p => ({label: p.label, value: p.value, key: p.value}))}
                                onChange={(e: any) => setSelectedProductType(e.value)}
                            />
                        </>
                        : <Select
                            label="Campaign"
                            data={marketoConfig.products.find(p => p.name.toLowerCase() === selectedProductLabel.toLowerCase())?.campaigns.filter(c => (!c.internal || mauUser) && (allowedCampaignTypes.includes(c.type))).map(campaign => {
                                const campaignDescription = campaign.description;
                                const audience = campaignDescription.match(/\((.*)\)/)?.[1] ?? campaignDescription;
                                return {
                                    label: campaign.name,
                                    className: "custom-select-option",
                                    description: <div>
                                        <S.CampaignName>{campaign.name}</S.CampaignName>
                                        <S.CampaignAudienceDescription>{audience}</S.CampaignAudienceDescription>
                                        <S.CampaignAudienceDescription>{campaignTypeToDropdownOption(campaign.type).label}</S.CampaignAudienceDescription>
                                    </div>,
                                    key: campaign.id,
                                    value: campaign,
                                };
                            })}
                            onChange={(e: any) => setSelectedCampaign(e.value as MarketoCampaign)}
                            size={Size.FULL}
                        />
                    }
                    {selectedCampaign && <>
                        <S.InfoLabel>Campaign ID: {selectedCampaign.id}</S.InfoLabel>
                        <Tooltip title="Needed for preview generation" delay={500}>
                            <S.InfoLabel>Email ID: {selectedCampaign.email}</S.InfoLabel>
                        </Tooltip>
                    </>}
                </S.CampaignInputs>
            </>
        }
    </>;

};



interface ModalProps extends Props {
    show: boolean;
    onClose(): void;
    loading?: boolean;
}

export const CampaignSelectorDialog: React.FC<ModalProps> = (props) => {

    const {
        show,
        productTypes,
        onClose,
        onCampaignSelected,
        loading,
        currentCampaignIsInvalid,
        allowedCampaignTypes,
    } = props;

    const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);

    return <Dialog show={show} onCloseAttempt={currentCampaignIsInvalid ? undefined : onClose}>
        <SS.DialogContent>
            <CampaignSelector
                productTypes={productTypes}
                onCampaignSelected={setSelectedCampaign}
                allowedCampaignTypes={allowedCampaignTypes}
            />
            <p>selected: {selectedCampaign ?? JSON.stringify(selectedCampaign)}</p>
        </SS.DialogContent>
        <SS.DialogControls>
            <Button
                onClick={() => onCampaignSelected(selectedCampaign!)}
                primary
                disabled={!selectedCampaign}
                loader={loading}
            >
                Confirm
            </Button>
            {currentCampaignIsInvalid && <Button onClick={onClose}>Cancel</Button>}
        </SS.DialogControls>
    </Dialog>;

};
