import {SendoutSource, SendoutState} from "./sendout";
import {SendoutIssue} from "./sendout-issue";

export class DigestSendout {
    constructor(
        public readonly id: string,
        public readonly title: string,
        public readonly description: string,
        public readonly items: DigestItem[],
        public readonly state: SendoutState,
        public readonly product: string,
        public readonly createdAt: Date,
        public readonly creatorEmail: string,
        public readonly creatorName: string,
        public readonly targetCampaignId: number,
        public readonly emailPreheader: string,
        public readonly issues: SendoutIssue[],
        public readonly generatedContent: string,
        public readonly scheduledFor: Date | null,
        public readonly sentAt: Date | null,
        public readonly errorDescription: string | null,
        public readonly triggeredBy: string | null,
    ) {}

    static parse(obj: any): DigestSendout {
        const issues = obj.issues?.map((i: any) => new SendoutIssue(
            i.type,
            i.name,
            i.description,
            i.severity,
        )) ?? [];
        return new DigestSendout(
            obj.id,
            obj.title,
            obj.description,
            (obj.items ?? []).map((o: any) => DigestItem.parse(o)),
            obj.state,
            obj.product,
            new Date(obj.createdAt),
            obj.creatorEmail,
            obj.creatorName,
            obj.targetCampaignId,
            obj.emailPreheader,
            issues,
            obj.generatedContent,
            obj.scheduledFor ? new Date(obj.scheduledFor) : null,
            obj.sentAt ? new Date(obj.sentAt) : null,
            obj.errorDescription ?? null,
            obj.triggeredBy ?? null,
        );
    }
}

export class DigestItem {
    constructor(
        public readonly contentId: string,
        public readonly title: string,
        public readonly selectedContent: string,
        public readonly originalContent: string,
        public readonly selectedImage: string | null,
        public readonly allImages: string[],
        public readonly selectedButton: DigestItemButton | null,
        public readonly allButtons: DigestItemButton[],
    ) {}

    copy(updates: Partial<DigestItem>): DigestItem {
        return new DigestItem(
            updates.contentId ?? this.contentId,
            updates.title ?? this.title,
            updates.selectedContent ?? this.selectedContent,
            updates.originalContent ?? this.originalContent,
            updates.selectedImage ?? this.selectedImage,
            updates.allImages ?? this.allImages,
            updates.selectedButton ?? this.selectedButton,
            updates.allButtons ?? this.allButtons,
        );
    }

    static parse(obj: any): DigestItem {
        return new DigestItem(
            obj.contentId,
            obj.title,
            obj.selectedContent,
            obj.originalContent,
            obj.selectedImage ?? null,
            obj.allImages ?? [],
            obj.selectedButton ?? null,
            obj.allButtons ?? [],
        );
    }
}

export interface DigestItemButton {
    label: string;
    url: string;
}
