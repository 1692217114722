import React from "react";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";

interface Props {
    name: string;
    active: boolean;
    onToggle(): void;
}

export const ToggleableProcessor: React.FC<Props> = (props) => {

    const {name, onToggle, active} = props;

    const formattedName = name.replace("-postprocessor", "").replace("-preprocessor", "");

    return <div>
        <Tooltip title={getProcessorDescription(name)}>
            <Toggle checked={active} onChange={_ => onToggle()} pale>
                {formattedName}
            </Toggle>
        </Tooltip>
    </div>;

};

function getProcessorDescription(processorName: string): string {

    switch (processorName) {

        case "buttons-preprocessor":
            return "Custom styles for buttons (including download-buttons)";
        case "convert-to-summary-preprocessor":
            return "Inserts \"Read more\" button when a special tag is found in a post's html";
        case "annotated-preprocessor":
            return "Wraps the content with a div marked with 'content' class";
        case "kotlin-form-with-button-replacer-preprocessor":
            return "Replaces some specific survey form (or any element with id='more-6857') with a button with a post's url";
        case "subscription-buttons-remover-preprocessor":
            return "Removes all buttons 'Subscribe to...'";

        case "appcode-duplicate-br-postprocessor":
            return "Sanitizes duplicated <br>-tags (at the moment is in use only in Appcode campaigns)";
        case "appcode-links-postprocessor":
            return "Changes urls of links starting with '#' (at the moment is in use only in Appcode campaigns)";
        case "centered-title-postprocessor":
            return "Custom styles for post's header (including 'text-align: center;')";
        case "inline-code-postprocessor":
            return "Custom styles for <code>-elements";
        case "annotated-postprocessor":
            return "Removes some specific subscription-links";
        case "marketo-symbols-postprocessor":
            return "Escapes some characters (like '#' and '$') that have special meaning for Marketo templating engine"
        case "digest-postprocessor":
            return "Formats <article> elements as digest entries, changing their titles, image positioning and buttons";
        case "digest-style-title-postprocessor":
            return "Formats an email's title so that it will be centered and containing a date on a separate line";
        default:
            return "Unknown processor";

    }

}
