import React, {useState} from "react";
import {Modal} from "../Modal/Modal";
import {useAppDispatch} from "../../redux/store";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import Radio from "@jetbrains/ring-ui/dist/radio/radio";
import Input from "@jetbrains/ring-ui/dist/input/input";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {digestsActions} from "../../redux/slices/digests-slice";

interface Props {
    open: boolean;
    onClose(): void;
    sendoutId: string;
    isDigest?: boolean;
}

const ALLOWED_DOMAINS = [
    "@jetbrains.com",
    "@litmusemail.com",
];

export const GetEmailSampleModal: React.FC<Props> = (props) => {

    const {open, onClose, sendoutId, isDigest} = props;
    const dispatch = useAppDispatch();

    const [isOwnEmail, setIsOwnEmail] = useState(true);
    const [customEmail, setCustomEmail] = useState("");

    const emailDomainIsAllowed = ALLOWED_DOMAINS.some(d => customEmail.toLowerCase().endsWith(d));
    const formIsValid = isOwnEmail || (customEmail.match(/.+@.+/) && emailDomainIsAllowed);

    const send = (email: string | undefined) => {
        if (formIsValid) {
            if (isDigest) {
                dispatch(digestsActions.sendDigestSample({digestId: sendoutId, customEmail: email}));
            } else {
                dispatch(sendoutsActions.sendSample({sendoutId, customEmail: email}));
            }
            onClose();
        } else {
            throw Error("An attempt to submit an invalid form");
        }
    };

    return <Modal
        show={open}
        onClose={onClose}
        title="Send sample email"
        actions={[{
            fn: () => send(isOwnEmail ? undefined : customEmail),
            label: "Send",
            primary: true,
            disabled: !formIsValid,
        }]}
    >
        <Radio onChange={value => setIsOwnEmail(value === "true")}>
            <Radio.Item defaultChecked={isOwnEmail} value="true">Send to me</Radio.Item>
            <Radio.Item defaultChecked={!isOwnEmail} value="false">Send to somebody else</Radio.Item>
            {!isOwnEmail && <div style={{marginTop: "12px"}}>
                <Input
                    value={customEmail}
                    onChange={e => setCustomEmail(e.target.value)}
                    label="E-Mail"
                    placeholder="some-custom-email@jetbrains.com"
                    size={Size.FULL}
                    autoComplete="off"
                    error={customEmail.includes("@") && !emailDomainIsAllowed
                        ? `Only ${ALLOWED_DOMAINS.join(" / ")} addresses are allowed`
                        : undefined}
                />
            </div>}
        </Radio>
    </Modal>;

};
