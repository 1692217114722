import styled from "styled-components";

export const PostsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
  max-height: 430px;
  overflow-y: auto;
  margin-top: 16px;
`;

export const Post = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
`;

export const PostImage = styled.img`
  height: 40px;
`;

export const PostTitle = styled.span`
  flex-grow: 1;
  font-size: 0.9em;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const NumericCheckbox = styled.button`
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer;
    color: white;
    font-weight: 600;
    background-color: #4e9fe1;
    &:empty {
      background-color: #EFEFEF;
      &:hover {
        background-color: #a9d0ed;
      }
    }
`;
