import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {useParams} from "react-router-dom";
import {CONFIG} from "../../config";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {digestsActions} from "../../redux/slices/digests-slice";
import Input from "@jetbrains/ring-ui/dist/input/input";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {JetblogButton} from "../JetblogButton/JetblogButton";
import {DigestItem} from "../../models/digest-sendout";
import {EditDigestItemModal} from "../EditDigestItemModal/EditDigestItemModal";
import {CampaignSelectorDialog} from "../CampaignSelectorDialog/CampaignSelectorDialog";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {FaArrowDown, FaArrowUp, FaCheck, FaEdit} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import {
    BtnBold,
    BtnItalic,
    BtnLink,
    BtnStrikeThrough, BtnStyles,
    BtnUnderline,
    Editor,
    EditorProvider, Toolbar
} from "react-simple-wysiwyg";
import {subscribeActionAfter} from "redux-subscribe-action";
import {ButtonsDock} from "../ButtonsDock/ButtonsDock";
import {RiMailSendFill} from "react-icons/ri";
import {GetEmailSampleModal} from "../GetEmailSampleModal/GetEmailSampleModal";
import {CampaignType} from "../../models/marketo-config";

enum EditorState {
    LOADING,
    IDLE,
    SAVING,
    REGENERATING_DESCRIPTION,
}

export const DigestEditorPage: React.FC = () => {

    const urlParams = useParams();
    const digestId: string = urlParams.digestId!;
    const dispatch = useAppDispatch();

    const digest = useAppSelector(state => state.digests.digestToEdit);

    const [editorState, setEditorState] = useState(EditorState.LOADING);
    const [title, setTitle] = useState(digest?.title ?? "");
    const [description, setDescription] = useState(digest?.description ?? "");
    const [authorName, setAuthorName] = useState(digest?.creatorName ?? "");
    const [itemToEdit, setItemToEdit] = useState<DigestItem | null>(null);
    const [iframeState, setIframeState] = useState(0);
    const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(digest?.targetCampaignId ?? null);
    const [showSampleModal, setShowSampleModal] = useState(false);

    const [showCampaignSelector, setShowCampaignSelector] = useState(false);

    const digestSettingsAreValid = title.trim() !== "" && authorName.trim() !== "" && selectedCampaignId !== null;
    const digestSettingsChanged = title !== digest?.title || authorName !== digest?.creatorName
        || selectedCampaignId !== digest?.targetCampaignId || description !== digest?.description;

    const previewUrl = CONFIG.apiHost + `api/digests/${digestId}/preview`;

    useEffect(() => {
        subscribeActionAfter(digestsActions.onDigestToEditUpdated.type, () => setEditorState(EditorState.IDLE));
    }, []);

    useEffect(() => {
        setEditorState(EditorState.LOADING);
        dispatch(digestsActions.loadDigestToEdit(digestId));
    }, [digestId]);

    useEffect(() => {
        if (digest) {
            setTitle(digest.title);
            setAuthorName(digest.creatorName);
            setSelectedCampaignId(digest.targetCampaignId);
            setDescription(digest.description);
            setIframeState(iframeState + 1);
        }
    }, [digest]);

    function save(): void {
        if (digestSettingsChanged && digestSettingsAreValid) {
            dispatch(digestsActions.editDigest({
                digestId,
                title: title === (digest?.title ?? "") ? undefined : title.trim(),
                authorName: authorName === (digest?.creatorName ?? "") ? undefined : authorName.trim(),
                campaignId: selectedCampaignId === digest?.targetCampaignId ? undefined : selectedCampaignId,
                description: description === (digest?.description ?? "") ? undefined : description.trim(),
            }));
        }
    }

    function regenerateDescription(): void {
        setEditorState(EditorState.REGENERATING_DESCRIPTION);
        dispatch(digestsActions.editDigest({
            digestId,
            description: ":generate:",
        }));
    }

    function saveItem(item: DigestItem): void {
        setEditorState(EditorState.SAVING);
        dispatch(digestsActions.editDigestItem({
            digestId,
            itemId: item.contentId,
            title: item.title,
            selectedContent: item.selectedContent,
            selectedImage: item.selectedImage ?? undefined,
            selectedButton: item.selectedButton ?? undefined,
        }));
        setItemToEdit(null);
    }

    function moveItem(item: DigestItem, targetIndex: number): void {
        if (targetIndex >= 0 && targetIndex < digest!.items.length) {
            dispatch(digestsActions.editDigestItem({
                digestId,
                itemId: item.contentId,
                index: targetIndex,
            }));
        } else {
            console.error(`Invalid target index for item ${item.contentId}: ${targetIndex}`);
        }
    }

    function deleteItem(item: DigestItem): void {
        dispatch(digestsActions.deleteDigestItem({
            digestId,
            itemId: item.contentId,
        }));
    }

    return <S.EditorPageLayout>
        <S.EditorLayout>
            <S.SidePanel>
                <div>
                    {digest === null
                        ? <Loader/>
                        : <S.FieldsList>
                            <S.FieldsSectionTitle>Email Meta:</S.FieldsSectionTitle>

                            <S.FieldLabel>Email title:</S.FieldLabel>
                            <Input
                                value={title}
                                size={Size.FULL}
                                onChange={e => setTitle(e.target.value)}
                            />

                            <S.FieldLabel>Author name:</S.FieldLabel>
                            <Input
                                value={authorName}
                                size={Size.FULL}
                                onChange={e => setAuthorName(e.target.value)}
                            />

                            <S.FieldLabel>Marketo campaign:</S.FieldLabel>
                            <Input
                                value={selectedCampaignId?.toString()}
                                size={Size.FULL}
                                disabled={true}
                            />
                            {/*<button onClick={() => setShowCampaignSelector(true)}>change</button>*/}

                            <S.FieldLabel>Digest description:</S.FieldLabel>
                            <EditorProvider>
                                <Editor value={description} onChange={(e) => setDescription(e.target.value)}>
                                    <Toolbar>
                                        <BtnBold/>
                                        <BtnItalic/>
                                        <BtnUnderline/>
                                        <BtnStrikeThrough/>
                                        <BtnLink/>
                                        <BtnStyles/>
                                    </Toolbar>
                                </Editor>
                            </EditorProvider>

                            <S.ButtonsContainer>
                                <JetblogButton
                                    onClick={regenerateDescription}
                                    disabled={editorState === EditorState.SAVING}
                                    loading={editorState === EditorState.REGENERATING_DESCRIPTION}
                                >
                                    Regenerate description
                                </JetblogButton>
                                <JetblogButton
                                    onClick={save}
                                    disabled={!digestSettingsChanged || !digestSettingsAreValid || editorState === EditorState.REGENERATING_DESCRIPTION}
                                    loading={editorState === EditorState.SAVING}
                                    display="block"
                                >
                                    Save changes
                                </JetblogButton>
                            </S.ButtonsContainer>

                            <S.FieldsSectionTitle>Digest Items:</S.FieldsSectionTitle>

                            <S.ItemsList>
                                {(digest?.items ?? []).map((item, idx) => <S.Item key={item.contentId}>
                                    <S.ItemLabel>– {item.title}</S.ItemLabel>
                                    <Button icon={FaEdit} onClick={() => setItemToEdit(item)} style={{flexShrink: 0}}/>
                                    <Button icon={FaArrowUp} onClick={() => moveItem(item, idx - 1)} style={{flexShrink: 0}} disabled={idx === 0}/>
                                    <Button icon={FaArrowDown} onClick={() => moveItem(item, idx + 1)} style={{flexShrink: 0}} disabled={idx >= digest!.items.length - 1}/>
                                    <Button icon={ImCross} onClick={() => deleteItem(item)} disabled={digest!.items.length === 1}/>
                                </S.Item>)}
                            </S.ItemsList>
                        </S.FieldsList>
                    }
                </div>
                <ButtonsDock options={[
                    {
                        title: "Verify",
                        description: "Samples & tests",
                        icon: FaCheck,
                        submenu: [
                            {label: "Send sample email", fn: () => setShowSampleModal(true)},
                        ],
                        popoverLeftOffset: 0,
                    },
                    {
                        title: "Send",
                        description: "Ready, set, go!",
                        icon: RiMailSendFill,
                        submenu: [
                            {label: "Send now", fn: () => {}},
                            {label: "Send later", fn: () => {}},
                        ],
                        popoverLeftOffset: -40,
                        disabled: true,
                    },
                ]}/>
            </S.SidePanel>
            <S.PreviewContainer>
                <S.Iframe
                    key={iframeState}
                    src={previewUrl}
                    style={{display: "block", height: "100%"}}
                    width="100%"
                />
            </S.PreviewContainer>
        </S.EditorLayout>

        {itemToEdit && <EditDigestItemModal
            open={true}
            onClose={() => setItemToEdit(null)}
            digestId={digestId}
            item={itemToEdit}
            onSave={saveItem}
        />}
        {digest && <CampaignSelectorDialog
            show={showCampaignSelector}
            onClose={() => setShowCampaignSelector(false)}
            productTypes={[digest.product]}
            onCampaignSelected={(id) => { setSelectedCampaignId(id); setShowCampaignSelector(false); }}
            allowedCampaignTypes={[CampaignType.DIGEST_SENDOUTS, CampaignType.UNIVERSAL]}
        />}
        {digest && <GetEmailSampleModal
            open={showSampleModal}
            onClose={() => setShowSampleModal(false)}
            sendoutId={digest.id}
            isDigest={true}
        />}
    </S.EditorPageLayout>;

};
