import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import {DigestItem} from "../../models/digest-sendout";
import Input from "@jetbrains/ring-ui/dist/input/input";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {
    BtnBold,
    BtnItalic,
    BtnLink,
    BtnStrikeThrough, BtnStyles,
    BtnUnderline,
    Editor,
    EditorProvider,
    Toolbar
} from "react-simple-wysiwyg";
import {Tabs} from "@jetbrains/ring-ui/dist/tabs/tabs";
import Tab from "@jetbrains/ring-ui/dist/tabs/tab";
import {DigestsApi} from "../../api/digests-api";

interface Props {
    open: boolean;
    onClose(): void;
    digestId: string;
    item: DigestItem;
    onSave(item: DigestItem): void;
}

enum ItemEditorTabs {
    CONTENT = "CONTENT",
    IMAGES = "IMAGES",
    BUTTONS = "BUTTONS",
}

export const EditDigestItemModal: React.FC<Props> = (props) => {

    const {open, onClose, digestId, item, onSave} = props;

    const [title, setTitle] = useState(item.title);
    const [html, setHtml] = useState(item.selectedContent);
    const [imageUrl, setImageUrl] = useState(item.selectedImage ?? "");
    const [buttonLabel, setButtonLabel] = useState(item.selectedButton?.label ?? "");
    const [buttonUrl, setButtonUrl] = useState(item.selectedButton?.url ?? "");

    const [generating, setGenerating] = useState(false);
    const [generationError, setGenerationError] = useState<string | null>(null);

    const [tab, setTab] = useState(ItemEditorTabs.CONTENT);

    const formChanged = title !== item.title || html !== item.selectedContent || imageUrl !== (item.selectedImage ?? "")
        || buttonLabel !== (item.selectedButton?.label ?? "") || buttonUrl !== (item.selectedButton?.url ?? "");
    const buttonIsValid = (buttonLabel.trim() === "" && buttonUrl.trim() === "") || (buttonLabel.trim() !== "" && buttonUrl.trim() !== "");
    const formIsValid = title.trim() !== "" && html.trim() !== "" && imageUrl.trim() !== "" && buttonIsValid;

    function save(): void {
        const patched = item.copy({
            title: title,
            selectedContent: html,
            selectedImage: imageUrl,
            selectedButton: {
                url: buttonUrl.trim(),
                label: buttonLabel.trim(),
            }
        });
        onSave(patched);
    }

    function reset(): void {
        setHtml(item.originalContent);
    }

    function regenerateContent(): void {
        setGenerating(true);
        DigestsApi.generateItemContent(digestId, item.contentId).subscribe({
            next: (content) => {
                setHtml(content);
                setGenerationError(null);
                setGenerating(false);
            },
            error: (err) => {
                setGenerationError("Content generation failed: " + (err.message ?? "<unknown reason>") + ".");
                setGenerating(false);
            },
        });
    }

    return <Modal
        show={open}
        title="Edit digest item"
        onClose={onClose}
        actions={[
            {
                label: "Save",
                disabled: !formChanged || !formIsValid,
                fn: save,
                primary: true,
            },
            ...(tab === ItemEditorTabs.CONTENT ? [
                {
                    label: "Regenerate content with AI",
                    fn: regenerateContent,
                    loading: generating,
                },
                {
                    label: "Reset content",
                    disabled: item.originalContent === html,
                    fn: reset,
                },
            ] : []),
        ]}
    >

        <Tabs selected={tab} onSelect={(t: string) => setTab(t as ItemEditorTabs)}>
            <Tab id={ItemEditorTabs.CONTENT} title="Title & Content">
                <S.FieldLabel>Title:</S.FieldLabel>
                <Input
                    value={title}
                    placeholder="Title"
                    onChange={e => setTitle(e.target.value)}
                    size={Size.FULL}
                />

                <S.FieldLabel>Content:</S.FieldLabel>
                <EditorProvider>
                    <Editor value={html} onChange={e => setHtml(e.target.value)}>
                        <Toolbar>
                            <BtnBold/>
                            <BtnItalic/>
                            <BtnUnderline/>
                            <BtnStrikeThrough/>
                            <BtnLink/>
                            <BtnStyles/>
                        </Toolbar>
                    </Editor>
                </EditorProvider>

                {generationError ?? <S.ErrorMessage>{generationError}</S.ErrorMessage>}
            </Tab>

            <Tab id={ItemEditorTabs.IMAGES} title="Images">
                <S.FieldLabel>Current item image's URL:</S.FieldLabel>
                <Input
                    value={imageUrl}
                    placeholder="Image URL"
                    onChange={e => setImageUrl(e.target.value)}
                    size={Size.FULL}
                />
                <S.ImagePreviewLarge src={imageUrl} alt="image preview"/>

                <S.FieldLabel>Images from the post:</S.FieldLabel>
                {item.allImages.length === 0
                    ? <p>(No images found)</p>
                    : <>
                        <S.ImagesContainer>
                            {item.allImages.map(img => <S.ImagePreviewSmall
                                key={img}
                                src={img}
                                alt="image preview"
                                onClick={() => setImageUrl(img)}
                            />)}
                        </S.ImagesContainer>
                    </>
                }
            </Tab>

            <Tab id={ItemEditorTabs.BUTTONS} title="Buttons">
                <S.FieldLabel>Selected action button label:</S.FieldLabel>
                <Input
                    value={buttonLabel}
                    placeholder="Button label (leave empty to use no button)"
                    onChange={e => setButtonLabel(e.target.value)}
                    size={Size.FULL}
                />

                <S.FieldLabel>Selected action button URL:</S.FieldLabel>
                <Input
                    value={buttonUrl}
                    placeholder="Button URL (leave empty to use no button)"
                    onChange={e => setButtonUrl(e.target.value)}
                    size={Size.FULL}
                />

                <S.FieldLabel>Action buttons from the post:</S.FieldLabel>
                {item.allButtons.length === 0
                    ? <p>(No buttons found)</p>
                    : <>
                        <S.ButtonsContainer>
                            {item.allButtons.map(btn => <S.ButtonPreview
                                key={btn.label}
                                onClick={() => {setButtonUrl(btn.url); setButtonLabel(btn.label)}}
                            >
                                {btn.label}
                            </S.ButtonPreview>)}
                        </S.ButtonsContainer>
                    </>
                }
            </Tab>
        </Tabs>

    </Modal>;

};
