import {CONFIG} from "../config";
import {CampaignType} from "../models/marketo-config";

const POST_AUTHOR_SELECTOR = "h4[data-post-author]";

export function generatePreviewUrl(sendoutId: string, campaignId?: number | null): string {
    return `${CONFIG.apiHost}api/sendouts/${sendoutId}/preview${campaignId ? `?campaign=${campaignId}` : ""}`;
}

export function unescapeString(str: string): string {
    return str.replaceAll("&#35;", "#").replaceAll("&#36;", "$");
}

export function getCampaignLink(campaignId: number): string {
    return `https://experience.adobe.com/#/@jetbrains/so:426-QVD-114/marketo-engage/classic/SC${campaignId}A1ZN19`;
}

export function checkIfAuthorNameIsDisplayed(contentHtml: string): boolean | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentHtml, "text/html");
    const node = doc.querySelector(POST_AUTHOR_SELECTOR);
    if (!node) {
        return null;
    }
    return node.innerHTML !== "";
}

export function changeAuthorNameVisibility(contentHtml: string, visible: boolean): string | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentHtml, "text/html");
    const node = doc.querySelector(POST_AUTHOR_SELECTOR);
    const authorName = node?.getAttribute("data-post-author");
    if (!node || !authorName) {
        return null;
    }
    node.innerHTML = visible ? authorName : "";
    return doc.documentElement.outerHTML;
}

export function setAuthorNameInContent(contentHtml: string, name: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentHtml, "text/html");
    const node = doc.querySelector(POST_AUTHOR_SELECTOR);
    if (node) {
        const updatedValue = `By ${name}`;
        node.setAttribute("data-post-author", updatedValue);
        node.textContent = updatedValue;
    } else {
        console.warn("Unable to set a new author name: HTML node not found");
    }
    return doc.documentElement.outerHTML;
}

export function getCurrentAuthorName(contentHtml: string | null | undefined): string | null {
    if (!contentHtml) {
        return null;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentHtml, "text/html");
    const node = doc.querySelector(POST_AUTHOR_SELECTOR);
    return node?.getAttribute("data-post-author")?.replace(/^by /i, "") ?? null;
}

export function getDaysBetweenDates(date1: Date | string, date2: Date | string): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDiff = Math.abs(d2.getTime() - d1.getTime());
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}

export function getDateNDaysBefore(startDate: Date, days: number): Date {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() - days);
    return endDate;
}

export function getDateNDaysAfter(startDate: Date, days: number): Date {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + days);
    return endDate;
}

export function validateField(value: string, validation: {nonEmpty?: boolean, positiveNumber?: boolean}): string | undefined {
    if (validation.nonEmpty && value.trim() === "") {
        return "Must not be empty";
    } else if (value === "") {
        return undefined;
    }
    if (validation.positiveNumber && (!/^\d+$/.test(value) || value.startsWith("0"))) {
        return "Must be a number";
    }
    return undefined;
}

/**
 * Returns a "recommended" page size (number of elements) suitable for the feed page and for the sendouts page
 * based on the fact that each item has width=400px.
 */
export function getRecommendedPageSize() {
    const contentAreaWidth = window.innerWidth - 300;
    const gap = 48;
    const itemWidth = 400;
    const rowSize = Math.max(Math.floor((contentAreaWidth + gap) / (itemWidth + gap)), 1); // 2212 / 448
    return 3 * rowSize;
}

export function campaignTypeToDropdownOption(type: CampaignType): { label: string, value: CampaignType, key: string } {
    let label: string | null = null;
    switch (type) {
        case CampaignType.SINGLE_POST_SENDOUTS: {
            label = "Single-post sendouts";
            break;
        }
        case CampaignType.DIGEST_SENDOUTS: {
            label = "Digest sendouts";
            break;
        }
        case CampaignType.UNIVERSAL: {
            label = "Universal sendouts";
            break;
        }
    }
    if (label === null) {
        throw new Error(`Unknown campaign type: ${type}`);
    }
    return {label, value: type, key: type};
}
