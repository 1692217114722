import React, {useEffect, useState} from "react";
import * as S from "./styles";
import placeholder from "./placeholder.png";
import {Modal} from "../Modal/Modal";
import {ProductDescriptor} from "../../models/product-descriptor";
import {ContentDescriptor} from "../../models/content-descriptor";
import {ContentApi} from "../../api/content-api";
import {CampaignSelector} from "../CampaignSelectorDialog/CampaignSelectorDialog";
import {useAppDispatch} from "../../redux/store";
import {digestsActions} from "../../redux/slices/digests-slice";
import {CampaignType} from "../../models/marketo-config";

const MIN_POSTS_TO_PICK = 2;
const MAX_POSTS_NUMBER = 20;

interface Props {
    open: boolean;
    product: ProductDescriptor;
    onClose(): void;
}

export const DigestPicker: React.FC<Props> = (props) => {

    const {open, product, onClose} = props;
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState<ContentDescriptor[] | null>(null);
    const [selectedPosts, setSelectedPosts] = useState<ContentDescriptor[]>([]);
    const [campaignId, setCampaignId] = useState<number | null>(null);

    const formIsValid = campaignId !== null && selectedPosts.length >= MIN_POSTS_TO_PICK;

    useEffect(() => {
        setLoading(true);
        ContentApi.getProductPosts(product, 0, MAX_POSTS_NUMBER).subscribe({
            next: values => {
                const items = values.content;
                setPosts(items);
                setLoading(false);
            },
        })
    }, [product]);

    function togglePost(post: ContentDescriptor): void {
        if (selectedPosts.some(p => p.id === post.id)) {
            setSelectedPosts(selectedPosts.filter(p => p.id !== post.id));
        } else {
            setSelectedPosts([...selectedPosts, post]);
        }
    }

    function save(): void {
        if (formIsValid) {
            //onSelected(selectedPosts);
            setLoading(true);
            dispatch(digestsActions.createDigest({
                campaignId,
                contentIds: selectedPosts.map(p => p.id),
            }));
        } else {
            throw Error();
        }
    }

    return <Modal
        show={open}
        title={`Create a new ${product.label} digest`}
        onClose={onClose}
        actions={[
            {
                label: "Continue",
                fn: save,
                primary: true,
                disabled: !formIsValid,
                loading: loading,
            }
        ]}
    >
        <CampaignSelector
            productTypes={[product.value]}
            onCampaignSelected={setCampaignId}
            allowedCampaignTypes={[CampaignType.DIGEST_SENDOUTS, CampaignType.UNIVERSAL]}
        />
        <S.PostsList>
            {posts?.map((post, idx) => {
                    const orderNumber = selectedPosts.findIndex(p => p.id === post.id);
                    return <S.Post key={idx}>
                        <S.NumericCheckbox onClick={() => togglePost(post)}>
                            {orderNumber === -1 ? "" : (orderNumber + 1)}
                        </S.NumericCheckbox>
                        <S.PostImage src={post.image ?? placeholder}/>
                        <S.PostTitle>{post.title}</S.PostTitle>
                    </S.Post>;
                })
                ?? <p>Loading posts...</p>
            }
        </S.PostsList>
    </Modal>;

};
